import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    // redirect: { path: '/app/scanner' }
    name: 'home',
    components: {
      default: () => import('../components/HomePage.vue')
      }
  },
  {
    path: '/terms',
    name: 'terms',
    components: {
      default: () => import('../components/TermsOfService.vue')
      }
  },
  {
    path: '/privacy',
    name: 'privacy',
    components: {
      default: () => import('../components/PrivacyPolicy.vue')
      }
  },
  { path: '/signout',
  name: 'signout',
  components: {
    default: () => import('../components/SignIn.vue')
    }
  },
  { path: '/inference',
  name: 'inference',
  components: {
    default: () => import('../components/InferenceTest.vue')
    }
  },
  { path: '/callback',
  name: 'callback',
  components: {
    default: () => import('../components/QuickBooksCallback.vue')
    }
  },
  { path: '/connected',
  name: 'connected',
  props: route => ({ accessToken: route.query.accessToken, refreshToken: route.query.refreshToken, realmId: route.query.realmId }),
  components: {
    default: () => import('../components/QuickBooksConnected.vue')
    }
  },
  { path: '/signIn',
  name: 'signIn',
  components: {
    default: () => import('../components/SignIn.vue')
    }
  },
  { path: '/signUp',
    name: 'signUp',
    components: {
      default: () => import('../components/SignUp.vue')
    }
  },
  { path: '/excel',
    name: 'excel',
    components: {
      default: () => import('../components/ExcelTest.vue')
    }
  },
  { path: '/createStmt',
    name: 'createStmt',
    components: {
      default: () => import('../components/CreateStmt.vue')
    }
  },
  { path: '/app',
    name: 'info',
    components: {
      default: () => import('../components/InfoPage.vue')
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: () => import(/* webpackChunkName: "about" */ '../components/HomePage.vue')
  },
  { path: '/firebaseRecovery',
    name: 'firebaseRecovery',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../components/FirebaseRecovery.vue')
    }
  },
  {
    path: '/app/:appName',
    name: 'app',
    component: () => import(/* webpackChunkName: "about" */ '../components/WrapperComponent.vue'),
    children: [
      { path: 'bank',
        name: 'bank',
        components: {
          default: () => import('../components/Register.vue'),
          leftSideBar: () => import('../components/Banks.vue')
        }
      },
      { path: 'ar',
        name: 'ar',
        components: {
          default: () => import('../components/Register.vue')
        }
      },
      { path: 'ap',
        name: 'ap',
        components: {
          default: () => import('../components/Register.vue')
        }
      },
      { path: 'je',
        name: 'je',
        components: {
          default: () => import('../components/Register.vue')
        }
      },
      { path: 'reports',
        name: 'reports',
        components: {
          default: () => import('../components/ReportsComponent.vue')
        }
      },
      { path: 'charts',
        name: 'charts',
        components: {
          default: () => import('../components/DashboardWidget.vue')
        }
      },
      { path: 'docs/:docId?/:page?/:index?/:account?',
        name: 'docs',
        components: {
          default: () => import('../components/FileUploader.vue')
        }
        // ,
        // // props: { default: true, leftSideBar: false },
        // children:[
        //   { path: ':docId',
        //     components: {
        //     default: () => import('../components/ProcessCC.vue')
        //   }
        // }  
        // ]
      },
      { path: 'pdf/:docId',
        name: 'pdf',
        components: {
          default: () => import('../components/PdfViewer.vue')
        }
      },
      { path: 'admin',
        name: 'admin',
        components: {
         default: () => import(/* webpackChunkName: "about" */ '../components/CorpsAdmin.vue')
//          default: () => import(/* webpackChunkName: "about" */ '../components/ReportsComponent.vue')
        }
      },
      { path: 'confirmation',
        name: 'confirmation',
        components: {
        default: () => import(/* webpackChunkName: "about" */ '../components/StripeCallback.vue')
  //          default: () => import(/* webpackChunkName: "about" */ '../components/ReportsComponent.vue')
        }
      },
//       { path: 'account',
//         name: 'account',
//         components: {
//          default: () => import(/* webpackChunkName: "about" */ '../components/CorpSettings.vue')
// //          default: () => import(/* webpackChunkName: "about" */ '../components/ReportsComponent.vue')
//         }
//       },
      { path: 'flows',
        name: 'flows',
        components: {
          default: () => import('../components/CheckListsFlows.vue'),
          leftSideBar: () => import('../components/CheckListsFlows.vue')
        },
        children:[
          { path: ':flowId',
            components: {
              default: () => import('../components/CheckListsFlow.vue'),
              leftSideBar: () => import('../components/CheckListsFlow.vue')
            },  
            // props: { 
            //   // default: route => ({
            //   //   query: {
            //   //     variables: route.query.variables
            //   //   }
            //   // }),
            //   default: true,
            //   leftSideBar: true    
            // }
          }
        ]
      },
      { path: 'quickbooks',
        name: 'quickbooks',
        components: {
          default: () => import('../components/QuickBooksConnect.vue')
        }
      },
      { path: 'quickbooks-callback',
        name: 'quickbooks-callback',
        components: {
          default: () => import('../components/QuickBooksCallback.vue')
        }
      },
      { path: 'settings',
        name: 'settings',
        components: {
         default: () => import(/* webpackChunkName: "about" */ '../components/CorpsAdmin.vue')
        }
      },
      { path: 'createTestCorp',
        name: 'createTestCorp',
        components: {
          default: () => import(/* webpackChunkName: "about" */ '../components/CreateTestCorp.vue')
        }
      },
      { path: 'performanceTest',
        name: 'performanceTest',
        components: {
          default: () => import(/* webpackChunkName: "about" */ '../components/PerformanceTest.vue')
        }
      }
    ]
  },
//   {
//     path: '/files',
//     name: 'files',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../components/FileUploader.vue'),
//     props: { }
//   },
  {
    path: '/about/:id',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    props: { aboutProp: "This iteh Prop" }
  }
]

// console.log("process.env", process.env)

let router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
